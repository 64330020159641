const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3",
        

    },
   
    
    {
        label: "Sellers",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/pages-starter",
      
    },
    {
        label: "Users",
        icon: "mdi mdi-email-outline",
        isHasArrow: true,
        url: "/inbox",
       
    },
   
    
    {
        label: "Categories",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: "/tables-listjs" },
            { sublabel: "Form", link: "/tables-basic" },
            // { sublabel: "Email Compose", link: "/compose-email" },
        ],
    },
    {
        label: "Feature sellers",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: "/ui-cards" },
            { sublabel: "Form", link: "/ui-images" },
        ],
    },
    {
        label: "Coupons ",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        subItem: [
            { sublabel: "Table Approval", link: "/table-datatables" },
            { sublabel: "Table", link: "/userprofile" },
            // { sublabel: "Email Compose", link: "/compose-email" },
        ],
        
       
    },
    // {
    //     label: "Wallet & Withdraw ",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/ui-rating",
    // },
    {
        label: "Delievery Boys",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table Verification", link: "/icons-materialdesign" },
            { sublabel: "All", link: "/icon-boxicon" },
        ],
    },
   
    {
        label: "All product",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/icons-fontawesome",
    },
   
    {
        label: "Payment Info",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/icon-dripicons",
    },
    {
        label: "Today Deals",
        icon: "mdi mdi-truck-outline",
        isHasArrow: true,
        url: "/ui-alerts",
       
    },
    
    // subItem: [
    //     { sublabel: "Pending", link: "/ui-alerts" },
    //     { sublabel: "Cooking", link: "/ui-badge" },
    //     { sublabel: "Confirmed", link: "/ui-breadcrumb" },
    //     { sublabel: "Ready for delivery", link: "/ui-buttons" },
    //     { sublabel: "Food on the way", link: "/ui-cards" },
    //     { sublabel: "Refunded", link: "/ui-carousel" },
    //     { sublabel: "Delivered", link: "/ui-dropdowns" },
    //     { sublabel: "Refund requests", link: "/ui-images" },

       
    // ],
]
export default SidebarData;