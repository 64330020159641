import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const subcategories = {
  electronics: [
    { value: "phones", label: "Phones" },
    { value: "laptops", label: "Laptops" },
  ],
  furniture: [
    { value: "chairs", label: "Chairs" },
    { value: "tables", label: "Tables" },
  ],
  clothing: [
    { value: "men", label: "Men" },
    { value: "women", label: "Women" },
  ],
};

const FormValidations = () => {
  document.title = "Product Management | React Admin & Dashboard Template";

  // Form validation for product management fields
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: "",
      subcategory: "",
      price: "",
      description: "",
      stockQuantity: "",
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Please Enter Product Name"),
      subcategory: Yup.string().required("Please Select a Subcategory"),
      price: Yup.number().required("Please Enter Price").min(0, "Price must be a positive number"),
      description: Yup.string().required("Please Enter Description"),
      stockQuantity: Yup.number().required("Please Enter Stock Quantity").min(0, "Stock Quantity must be a positive number"),
    }),
    onSubmit: (values) => {
      console.log("Product Values:", values);
    },
  });

  // Determine available subcategories based on predefined subcategories
  const availableSubcategories = Object.values(subcategories).flat();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Products" breadcrumbItem="Product Management" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Product Management Form</h4>
                  <p className="card-title-desc">
                    Use this form to manage product information including name, subcategory, price, and stock quantity.
                  </p>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="productName">Product Name</Label>
                          <Input
                            name="productName"
                            placeholder="Product Name"
                            type="text"
                            id="productName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.productName}
                            invalid={validation.touched.productName && validation.errors.productName ? true : false}
                          />
                          {validation.touched.productName && validation.errors.productName ? (
                            <FormFeedback>{validation.errors.productName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="subcategory">Subcategory</Label>
                          <Input
                            type="select"
                            name="subcategory"
                            id="subcategory"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subcategory}
                            invalid={validation.touched.subcategory && validation.errors.subcategory ? true : false}
                          >
                            <option value="">Select Subcategory</option>
                            {availableSubcategories.map(subcat => (
                              <option key={subcat.value} value={subcat.value}>{subcat.label}</option>
                            ))}
                          </Input>
                          {validation.touched.subcategory && validation.errors.subcategory ? (
                            <FormFeedback>{validation.errors.subcategory}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            name="price"
                            placeholder="Price"
                            type="number"
                            id="price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price}
                            invalid={validation.touched.price && validation.errors.price ? true : false}
                          />
                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback>{validation.errors.price}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="stockQuantity">Stock Quantity</Label>
                          <Input
                            name="stockQuantity"
                            placeholder="Stock Quantity"
                            type="number"
                            id="stockQuantity"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.stockQuantity}
                            invalid={validation.touched.stockQuantity && validation.errors.stockQuantity ? true : false}
                          />
                          {validation.touched.stockQuantity && validation.errors.stockQuantity ? (
                            <FormFeedback>{validation.errors.stockQuantity}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            placeholder="Product Description"
                            type="textarea"
                            id="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback>{validation.errors.description}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormValidations;
