import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// Sample data for food items (replace this with actual data if needed)
const FoodItemsData = [
    {
        id: 1,
        itemName: 'Pizza Margherita',
        price: 12.99,
        subcategory: 'Pizzas',
        sales: 150,
    },
    {
        id: 2,
        itemName: 'Cheeseburger',
        price: 9.99,
        subcategory: 'Burgers',
        sales: 200,
    },
    {
        id: 3,
        itemName: 'Caesar Salad',
        price: 8.49,
        subcategory: 'Salads',
        sales: 120,
    },
    // Add more food items data as needed
];

const FoodItemsTable = () => {
    const handleDetailsClick = (itemName) => {
        // Implement the logic to view details for the specific food item
        alert(`Details for Food Item: ${itemName}`);
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Food Items Table</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Subcategory</th>
                                            <th scope="col">Sales</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {FoodItemsData.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={item.id} />
                                                        <label className="form-check-label" htmlFor={item.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for food item image or icon */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {item.itemName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{item.itemName}</td>
                                                <td>$ {item.price.toFixed(2)}</td>
                                                <td>{item.subcategory}</td>
                                                <td>{item.sales}</td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        className="me-1"
                                                        onClick={() => handleDetailsClick(item.itemName)}
                                                    >
                                                        View Details
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="danger"
                                                        size="sm"
                                                        className="me-1"
                                                    >
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FoodItemsTable;
