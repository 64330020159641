import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// Sample data for payment information (replace this with actual data if needed)
const PaymentInfoData = [
    {
        id: 1,
        name: 'John Doe',
        role: 'Rider',
        paymentDate: '2024-09-10',
        amount: 150.00,
        status: 'Paid',
        color: 'success',
    },
    {
        id: 2,
        name: 'Jane Smith',
        role: 'Seller',
        paymentDate: '2024-09-12',
        amount: 500.00,
        status: 'Pending',
        color: 'warning',
    },
    // Add more payment data as needed
];

const PaymentInfoTable = () => {
    const handleDetailsClick = (id) => {
        // Implement the logic to view details for the specific payment record
        alert(`Details for Payment ID: ${id}`);
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Payment Information</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Payment Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {PaymentInfoData.map((payment, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={payment.id} />
                                                        <label className="form-check-label" htmlFor={payment.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for avatar or user icon */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {payment.name.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{payment.name}</td>
                                                <td>{payment.role}</td>
                                                <td>{payment.paymentDate}</td>
                                                <td>$ {payment.amount.toFixed(2)}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${payment.color}`}></i> {payment.status}
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        className="me-1"
                                                        onClick={() => handleDetailsClick(payment.id)}
                                                    >
                                                        View Details
                                                    </Button>
                                                    {/* Add other action buttons if needed */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PaymentInfoTable;
