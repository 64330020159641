import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Define subscription plans (example plans)
const subscriptionPlans = [
  { value: "basic", label: "Basic Plan" },
  { value: "premium", label: "Premium Plan" },
  { value: "enterprise", label: "Enterprise Plan" },
];

const FormValidations = () => {
  document.title = "Subscription Management | React Admin & Dashboard Template";

  // Form validation for subscription creation fields
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      planName: "",
      price: "",
      duration: "",
      description: "",
      status: "",
    },
    validationSchema: Yup.object({
      planName: Yup.string().required("Please Enter Plan Name"),
      price: Yup.number().required("Please Enter Price").min(0, "Price must be a positive number"),
      duration: Yup.string().required("Please Enter Duration"),
      description: Yup.string().required("Please Enter Description"),
      status: Yup.string().required("Please Select a Status"),
    }),
    onSubmit: (values) => {
      console.log("Subscription Values:", values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Subscriptions" breadcrumbItem="Create Subscription" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Create Subscription Plan</h4>
                  <p className="card-title-desc">
                    Use this form to create a new subscription plan with details including plan name, price, duration, and description.
                  </p>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="planName">Subscription Name</Label>
                          <Input
                            name="planName"
                            placeholder="Plan Name"
                            type="text"
                            id="planName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.planName}
                            invalid={validation.touched.planName && validation.errors.planName ? true : false}
                          />
                          {validation.touched.planName && validation.errors.planName ? (
                            <FormFeedback>{validation.errors.planName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            name="price"
                            placeholder="Price"
                            type="number"
                            id="price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price}
                            invalid={validation.touched.price && validation.errors.price ? true : false}
                          />
                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback>{validation.errors.price}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="duration">Duration</Label>
                          <Input
                            name="duration"
                            placeholder="Duration (e.g., 1 month, 1 year)"
                            type="text"
                            id="duration"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.duration}
                            invalid={validation.touched.duration && validation.errors.duration ? true : false}
                          />
                          {validation.touched.duration && validation.errors.duration ? (
                            <FormFeedback>{validation.errors.duration}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="status">Status</Label>
                          <Input
                            type="select"
                            name="status"
                            id="status"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </Input>
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback>{validation.errors.status}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            placeholder="Subscription Description"
                            type="textarea"
                            id="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback>{validation.errors.description}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormValidations;
