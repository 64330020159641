import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';

// Sample data for riders (replace this with actual data if needed)
const RidersData = [
    {
        id: 1,
        riderName: 'John Doe',
        registrationDate: '2024-09-01',
        vehicle: 'Bike',
        status: 'Verified',
        color: 'success',
    },
    {
        id: 2,
        riderName: 'Jane Smith',
        registrationDate: '2024-09-05',
        vehicle: 'Scooter',
        status: 'Pending',
        color: 'warning',
    },
    // Add more rider data as needed
];

const RiderVerificationTable = () => {
    const [riders, setRiders] = useState(RidersData);

    const toggleVerification = (riderId) => {
        setRiders(riders.map(rider => 
            rider.id === riderId
                ? { ...rider, status: rider.status === 'Verified' ? 'Pending' : 'Verified', color: rider.status === 'Verified' ? 'warning' : 'success' }
                : rider
        ));
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Rider Verification</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Rider Name</th>
                                            <th scope="col">Registration Date</th>
                                            <th scope="col">Vehicle</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {riders.map((rider, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={rider.id} />
                                                        <label className="form-check-label" htmlFor={rider.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for rider icon or avatar */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {rider.riderName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{rider.riderName}</h5>
                                                </td>
                                                <td>{rider.registrationDate}</td>
                                                <td>{rider.vehicle}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${rider.color}`}></i> {rider.status}
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color={rider.status === 'Verified' ? 'warning' : 'success'}
                                                        size="sm"
                                                        onClick={() => toggleVerification(rider.id)}
                                                    >
                                                        {rider.status === 'Verified' ? 'Unverify' : 'Verify'}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default RiderVerificationTable;
