import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

// Dummy data for financial overview
const dummyFinancialData = {
  deposits: 1200.00,
  withdrawals: 300.00,
  expenses: 450.00,
};

const UserProfile = () => {
  document.title = "Profile | Upzet - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [idx, setIdx] = useState(1);
  const [balance, setBalance] = useState(0);
  const [deposits, setDeposits] = useState(dummyFinancialData.deposits);
  const [withdrawals, setWithdrawals] = useState(dummyFinancialData.withdrawals);
  const [expenses, setExpenses] = useState(dummyFinancialData.expenses);

  const { error, success } = useSelector((state) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setName(obj.displayName);
        setEmail(obj.email);
        setIdx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setName(obj.username);
        setEmail(obj.email);
        setIdx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  const handleAppeal = () => {
    // Add logic for appealing a withdrawal
    alert("Appeal for withdrawal submitted!");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Upzet" breadcrumbItem="Wallet & Withdraw" />

          <h4 className="card-title mb-4">Financial Overview</h4>

          <Card>
            <CardBody>
              <div className="text-muted">
                <h5 style={{ color: '#333' }}>Balance: $ {balance.toFixed(2)}</h5>
                <p className="mb-1">
                  This Month's Deposits: <span  style={{ color: 'green' }}>{deposits.toFixed(2)} $</span> 
                </p>
                <p className="mb-1" >
                  Expenses:  <span style={{ color: 'red' }}>{expenses.toFixed(2)} $</span>
                </p>
                <p className="mb-1">
                  Withdrawals:  {withdrawals.toFixed(2)} $
                </p>
              </div>
              <div className="text-center mt-4">
                <Button color="primary" onClick={handleAppeal}>
                  Appeal Withdraw
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
