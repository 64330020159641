import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { LatestTransationData } from '../../CommonData/Data/index';
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Mock data for sellers. Replace `LatestTransationData` with appropriate data.
const SellersData = LatestTransationData.map(item => ({
    ...item,
    avatar: 'https://via.placeholder.com/50', // Placeholder image
    status: Math.random() > 0.5 ? 'Active' : 'Inactive', // Mock status for demonstration
}));

const SellersTable = () => {
    const [sellers, setSellers] = useState(SellersData);

    const handleStatusChange = (id) => {
        setSellers(prevSellers => 
            prevSellers.map(seller =>
                seller.id === id
                    ? { ...seller, status: seller.status === 'Active' ? 'Inactive' : 'Active' }
                    : seller
            )
        );
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Breadcrumbs title="Seller Management" breadcrumbItem="Sellers Table" />
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Sellers to Manage</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col">Avatar</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Join Date</th>
                                            <th scope="col">Sales</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sellers.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={item.id} />
                                                        <label className="form-check-label" htmlFor={item.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <img src={item.avatar} alt={item.clientName} className="rounded-circle" style={{ width: '50px', height: '50px' }} />
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{item.clientName}</h5>
                                                </td>
                                                <td>{item.date}</td>
                                                <td>{item.quantity}</td>
                                                <td>
                                                    <div className="form-check form-switch form-switch-md">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={`statusSwitch-${item.id}`}
                                                            checked={item.status === 'Active'}
                                                            onChange={() => handleStatusChange(item.id)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`statusSwitch-${item.id}`}>
                                                            {item.status}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-primary btn-sm">Approve</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default SellersTable;
