import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Sample data for deals (replace this with actual data if needed)
const DealsData = [
    {
        id: 1,
        dealName: 'Summer Sale',
        restaurantName: 'Gourmet Grill',
        dealStartDate: '2024-08-01',
        dealEndDate: '2024-08-31',
        totalSales: 1500.00,
        status: 'Active',
        color: 'success',
    },
    {
        id: 2,
        dealName: 'Winter Discount',
        restaurantName: 'Pasta Palace',
        dealStartDate: '2024-12-01',
        dealEndDate: '2024-12-31',
        totalSales: 800.00,
        status: 'Upcoming',
        color: 'info',
    },
    // Add more deal data as needed
];

const DealsTable = () => {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    const toggleDropdown = (dealId) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [dealId]: !prevState[dealId]
        }));
    };

    const handleStatusChange = (dealId, newStatus) => {
        // Implement the logic to update the status of the specific deal
        alert(`Deal ID ${dealId} status updated to ${newStatus}`);
        setSelectedStatus(prevState => ({
            ...prevState,
            [dealId]: newStatus
        }));
        // Update state or make API call here
    };

    return (
        <React.Fragment>
            <Row style={{ paddingLeft: "30px", paddingRight: "30px",marginTop:'120px' }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">All Deals</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Deal Name</th>
                                            <th scope="col">Restaurant</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope="col">Total Sales</th>
                                            {/* <th scope="col">Status</th>
                                            <th scope="col">Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DealsData.map((deal, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={deal.id} />
                                                        <label className="form-check-label" htmlFor={deal.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for deal icon or logo */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {deal.dealName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{deal.dealName}</td>
                                                <td>{deal.restaurantName}</td>
                                                <td>{deal.dealStartDate}</td>
                                                <td>{deal.dealEndDate}</td>
                                                <td>$ {deal.totalSales.toFixed(2)}</td>
                                                {/* <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${deal.color}`}></i> 
                                                    {selectedStatus[deal.id] || deal.status}
                                                </td>
                                                <td>
                                                    <Dropdown isOpen={dropdownOpen[deal.id] || false} toggle={() => toggleDropdown(deal.id)}>
                                                        <DropdownToggle caret color="primary" size="sm">
                                                            {selectedStatus[deal.id] || 'Change Status'}
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ minWidth: '150px', maxHeight: '200px', overflowY: 'auto' }}>
                                                            <DropdownItem onClick={() => handleStatusChange(deal.id, 'Active')}>
                                                                Active
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(deal.id, 'Upcoming')}>
                                                                Upcoming
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(deal.id, 'Expired')}>
                                                                Expired
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(deal.id, 'Canceled')}>
                                                                Canceled
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DealsTable;
