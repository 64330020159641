import React from 'react';
import { Row, Col } from 'reactstrap';

// Dummy data for demonstration. Replace with your actual data.
const UsersData = [
    { id: 1, name: 'John Doe', email: 'john.doe@example.com', avatar: 'https://via.placeholder.com/50', createdAt: '2024-01-15' },
    { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', avatar: 'https://via.placeholder.com/50', createdAt: '2024-02-20' },
    // Add more user data as needed
];

const UsersTable = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Users List</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}>Avatar</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UsersData.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={`checkbox-${item.id}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${item.id}`}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <img src={item.avatar} alt={item.name} className="rounded-circle" style={{ width: '50px', height: '50px' }} />
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{item.name}</h5>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.createdAt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default UsersTable;
