import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Sample data for cooking status orders (replace this with actual data if needed)
const CookingStatusData = [
    {
        id: 1,
        restaurantName: 'Gourmet Grill',
        orderId: 'ORD125',
        orderDate: '2024-09-11',
        totalAmount: 55.00,
        cookingStatus: 'Preparing',
        color: 'info',
    },
    {
        id: 2,
        restaurantName: 'Pasta Palace',
        orderId: 'ORD126',
        orderDate: '2024-09-13',
        totalAmount: 40.00,
        cookingStatus: 'Cooking',
        color: 'info',
    },
    // Add more cooking status data as needed
];

const CookingStatus = () => {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    const toggleDropdown = (orderId) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    const handleStatusChange = (orderId, newStatus) => {
        // Implement the logic to update the cooking status of the specific order
        alert(`Order ID ${orderId} cooking status updated to ${newStatus}`);
        setSelectedStatus(prevState => ({
            ...prevState,
            [orderId]: newStatus
        }));
        // Update state or make API call here
    };

    return (
        <React.Fragment>
            <Row style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Cooking Status</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Restaurant</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Order Date</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Cooking Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CookingStatusData.map((order, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={order.id} />
                                                        <label className="form-check-label" htmlFor={order.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for restaurant icon or logo */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {order.restaurantName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{order.restaurantName}</td>
                                                <td>{order.orderId}</td>
                                                <td>{order.orderDate}</td>
                                                <td>$ {order.totalAmount.toFixed(2)}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${order.color}`}></i> 
                                                    {selectedStatus[order.id] || order.cookingStatus}
                                                </td>
                                                <td>
                                                    <Dropdown isOpen={dropdownOpen[order.id] || false} toggle={() => toggleDropdown(order.id)}>
                                                        <DropdownToggle caret color="primary" size="sm">
                                                            {selectedStatus[order.id] || 'Change Status'}
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ minWidth: '150px', maxHeight: '200px', overflowY: 'auto' }}>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Preparing')}>
                                                                Preparing
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Cooking')}>
                                                                Cooking
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Ready')}>
                                                                Ready
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Canceled')}>
                                                                Canceled
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CookingStatus;
