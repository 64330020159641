import React from 'react';
import { Row, Col } from 'reactstrap';

// Dummy data for demonstration. Replace with your actual data.
const CategoryData = [
    { id: 1, name: 'Electronics', parentCategory: 'Technology', description: 'Devices and gadgets', status: 'Active' },
    { id: 2, name: 'Clothing', parentCategory: 'Fashion', description: 'Apparel and accessories', status: 'Inactive' },
    // Add more Category data as needed
];

const CategoryTable = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Category Table</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Category Name</th>
                                            <th scope="col">Parent Category</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CategoryData.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={`checkbox-${item.id}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${item.id}`}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.image ? (
                                                        <img src={item.image} alt="Category" className="avatar-xs rounded-circle" />
                                                    ) : (
                                                        <div className="avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                                {item.name.charAt(0)}
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                   
                                                    <h5 className="font-size-15 mb-0">{item.name}</h5>
                                                </td>
                                                <td>{item.parentCategory}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + (item.status === 'Active' ? 'success' : 'danger')}></i> {item.status}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-1">Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default CategoryTable;
