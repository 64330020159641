import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormValidations = () => {
  document.title = "Form Validation | Upzet - React Admin & Dashboard Template";

  // Form validation for food add-ons
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      addOnName: "",
      description: "",
      price: "",
      quantityOptions: "",
      inStock: false,
      specialAvailability: "",
      dietaryTags: [],
      allergens: "",
      customInstructions: "",
      image: null,
    },
    validationSchema: Yup.object({
      addOnName: Yup.string().required("Add-On Name is required"),
      description: Yup.string().required("Description is required"),
      price: Yup.number().required("Price is required").positive("Price must be positive"),
      quantityOptions: Yup.string().required("Quantity Options are required"),
      inStock: Yup.boolean(),
      specialAvailability: Yup.string(),
      dietaryTags: Yup.array().of(Yup.string()),
      allergens: Yup.string(),
      customInstructions: Yup.string(),
      image: Yup.mixed().nullable()
        .test("fileSize", "File is too large", value => value && value.size <= 2000000) // 2MB max
        .test("fileFormat", "Unsupported Format", value => value && ['image/jpeg', 'image/png'].includes(value.type)),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Food Add-On Management" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Food Add-On Management</h4>
                  <p className="card-title-desc">
                    Manage your food add-ons with detailed options and validations.
                  </p>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="addOnName">Add-On Name</Label>
                          <Input
                            name="addOnName"
                            type="text"
                            id="addOnName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.addOnName}
                            invalid={validation.touched.addOnName && validation.errors.addOnName ? true : false}
                          />
                          {validation.touched.addOnName && validation.errors.addOnName ? (
                            <FormFeedback>{validation.errors.addOnName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            type="textarea"
                            id="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback>{validation.errors.description}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            name="price"
                            type="number"
                            id="price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price}
                            invalid={validation.touched.price && validation.errors.price ? true : false}
                          />
                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback>{validation.errors.price}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="quantityOptions">Quantity Options</Label>
                          <Input
                            name="quantityOptions"
                            type="text"
                            id="quantityOptions"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.quantityOptions}
                            invalid={validation.touched.quantityOptions && validation.errors.quantityOptions ? true : false}
                          />
                          {validation.touched.quantityOptions && validation.errors.quantityOptions ? (
                            <FormFeedback>{validation.errors.quantityOptions}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3" style={{display:'flex',columnGap:'8px'}}>
                          <Label htmlFor="inStock">In Stock</Label>
                          <Input
                            name="inStock"
                            type="checkbox"
                            id="inStock"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            checked={validation.values.inStock}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="specialAvailability">Special Availability</Label>
                          <Input
                            name="specialAvailability"
                            type="text"
                            id="specialAvailability"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.specialAvailability}
                            invalid={validation.touched.specialAvailability && validation.errors.specialAvailability ? true : false}
                          />
                          {validation.touched.specialAvailability && validation.errors.specialAvailability ? (
                            <FormFeedback>{validation.errors.specialAvailability}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dietaryTags">Dietary Tags</Label>
                          <Input
                            name="dietaryTags"
                            type="text"
                            id="dietaryTags"
                            placeholder="Comma separated tags (e.g., Gluten-Free, Vegan)"
                            onChange={e => {
                              const tags = e.target.value.split(',').map(tag => tag.trim());
                              validation.setFieldValue("dietaryTags", tags);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.dietaryTags.join(', ')}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="allergens">Allergens</Label>
                          <Input
                            name="allergens"
                            type="text"
                            id="allergens"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.allergens}
                            invalid={validation.touched.allergens && validation.errors.allergens ? true : false}
                          />
                          {validation.touched.allergens && validation.errors.allergens ? (
                            <FormFeedback>{validation.errors.allergens}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="customInstructions">Custom Instructions</Label>
                          <Input
                            name="customInstructions"
                            type="textarea"
                            id="customInstructions"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.customInstructions}
                            invalid={validation.touched.customInstructions && validation.errors.customInstructions ? true : false}
                          />
                          {validation.touched.customInstructions && validation.errors.customInstructions ? (
                            <FormFeedback>{validation.errors.customInstructions}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="image">Image</Label>
                          <Input
                            name="image"
                            type="file"
                            id="image"
                            onChange={event => {
                              validation.setFieldValue("image", event.currentTarget.files[0]);
                            }}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.image && validation.errors.image ? true : false}
                          />
                          {validation.touched.image && validation.errors.image ? (
                            <FormFeedback>{validation.errors.image}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormValidations;
