import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { LatestTransationData } from '../../CommonData/Data/index';

const DataTables = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Transaction</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Food Item</th>
                                            <th scope="col">Coupon Code</th>
                                            <th scope="col">Usage Limit</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Discount Percentage</th>
                                            <th scope="col">Cost</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LatestTransationData.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={item.id} />
                                                        <label className="form-check-label" htmlFor={item.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.src ? (
                                                        <img src={item.src} alt="user" className="avatar-xs " />
                                                    ) : (
                                                        <div className="avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                                {item.clientName.charAt(0)}
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{item.clientName}</h5>
                                                </td>
                                                <td>{item.clientId}</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>8%</td>
                                                <td>$2</td>
                                                <td>
                                                    <Button color="outline-success" size="sm" className="me-1">Edit</Button>
                                                    <Button color="outline-danger" size="sm" className="me-1">Cancel</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DataTables;
