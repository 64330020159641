import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Sample data for confirmed orders (replace this with actual data if needed)
const ConfirmedOrdersData = [
    {
        id: 1,
        restaurantName: 'Sushi Central',
        orderId: 'ORD127',
        orderDate: '2024-09-15',
        totalAmount: 75.00,
        confirmationStatus: 'Confirmed',
        color: 'success',
    },
    {
        id: 2,
        restaurantName: 'Burger Haven',
        orderId: 'ORD128',
        orderDate: '2024-09-16',
        totalAmount: 22.00,
        confirmationStatus: 'Pending',
        color: 'warning',
    },
    // Add more confirmed orders data as needed
];

const ConfirmedOrders = () => {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    const toggleDropdown = (orderId) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    const handleStatusChange = (orderId, newStatus) => {
        // Implement the logic to update the confirmation status of the specific order
        alert(`Order ID ${orderId} confirmation status updated to ${newStatus}`);
        setSelectedStatus(prevState => ({
            ...prevState,
            [orderId]: newStatus
        }));
        // Update state or make API call here
    };

    return (
        <React.Fragment>
            <Row style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Confirmed Orders</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Restaurant</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Order Date</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Confirmation Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ConfirmedOrdersData.map((order, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={order.id} />
                                                        <label className="form-check-label" htmlFor={order.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for restaurant icon or logo */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {order.restaurantName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{order.restaurantName}</td>
                                                <td>{order.orderId}</td>
                                                <td>{order.orderDate}</td>
                                                <td>$ {order.totalAmount.toFixed(2)}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${order.color}`}></i> 
                                                    {selectedStatus[order.id] || order.confirmationStatus}
                                                </td>
                                                <td>
                                                    <Dropdown isOpen={dropdownOpen[order.id] || false} toggle={() => toggleDropdown(order.id)}>
                                                        <DropdownToggle caret color="primary" size="sm">
                                                            {selectedStatus[order.id] || 'Change Status'}
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ minWidth: '150px', maxHeight: '200px', overflowY: 'auto' }}>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Confirmed')}>
                                                                Confirmed
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Pending')}>
                                                                Pending
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(order.id, 'Rejected')}>
                                                                Rejected
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ConfirmedOrders;
